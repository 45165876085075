import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomTableCp from './CustomTableCp';
import axios from 'axios';

const EmployeeDetailCp = ({ employee }) => {
  const [employeeDetail, setEmployeeDetail] = useState({
    id: "",
    user_id: "",
    first_name: "",
    last_name: "",
    date_hire: "",
    o_f_type: "",
    title: "",
    email: "",
    phone: "",
    department: "",
    pm: "",
    dob: "",
    avatar: ""
  });
  const [employeeCerts, setEmployeeCerts] = useState([]);
  const [employeeFleet, setEmployeeFleet] = useState([]);
  const navigate = useNavigate();
  const [access, setAccess] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchEmployeeDetail = async () => {
      try {
      const response = await axios.get(`${apiUrl}/employee_crud.php?getDetail=${employee?.id}`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      });
        const data = response.data;
        if(data)
        {
          setEmployeeDetail(data['employee_details']);
          setEmployeeCerts(data['employee_certs'] || []);
          setEmployeeFleet(data['employee_fleet'] || '');
          setAccess(data['access']);
        }
      } catch (error) {
        toast.error("Oops minor issue with the data.", {
          position: "top-center"
        });
        if (error.response && error.response.status === 401) {
          toast.error("Session expired. Please login again.", {
            position: "top-center"
          });
          navigate('/login');
        } else if (error.response && error.response.status === 403) {
          toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
            position: "top-center"
          });
        }
      }
    };
    fetchEmployeeDetail();
  }, [employee, apiUrl, navigate]);

  const handleEditCert = async(editedCert) => {
    const bodyData = {
      data: editedCert,
      status: 'submit_cert'
    };
    
      try {
        const response = await axios.post(`${apiUrl}/employee_crud.php`, bodyData, {
          headers: { 'Content-Type': 'application/json' }
        });

        if (response.data.success) {
          toast.success("Cert successfully saved!", {
            position: "top-center"
          });
          setEmployeeCerts((prevCerts) => prevCerts.map(cert => cert.id === editedCert.id ? editedCert : cert));
        } else {
          toast.error(response.data.error || "Failed updating.", {
            position: "top-center"
          });
        }
      } catch (error) {
          if (error.response && error.response.status === 401) {
          toast.error("Session expired. Please login again.", {
          position: "top-center"
          });
          navigate('/login');
          } else if (error.response && error.response.status === 403) {
            setAccess(true);
          toast.error("Oops!! no access to edit.", {
          position: "top-center"
          });
          }
      }
  };

  const handleAddCert = async(newCert) => {
    // API call to add certificate
    if(newCert.certification === '' || newCert.issue === '')
    {
      toast.error("missing data in the form", {
        position: "top-center"
      });
      return;
    }
    const bodyData = {
      data: newCert,
      employee_id: employee?.id,
      status: 'submit_cert'
    };
      try {
        const response = await axios.post(`${apiUrl}/employee_crud.php`, bodyData, {
          headers: { 'Content-Type': 'application/json' }
        });

        if (response.data.success) {
          toast.success("Cert successfully saved!", {
            position: "top-center"
          });
          const newID = (response.data[0]? response.data[0] : null);
          setEmployeeCerts((prevCerts) => [...prevCerts, { ...newCert, id: newID }]);
        } else {
          toast.error(response.data.error || "Failed updating.", {
            position: "top-center"
          });
        }
      } catch (error) {
          if (error.response && error.response.status === 401) {
          toast.error("Session expired. Please login again.", {
          position: "top-center"
          });
          navigate('/login');
          } else if (error.response && error.response.status === 403) {
            setAccess(true);
          toast.error("Oops!! no access to edit.", {
          position: "top-center"
          });
          }
      }    
  };

  const handleDelete = async(id) => {
      const bodyData = {
      cert_id: id,
      status: 'delete_cert'
    };
    
      try {
        const response = await axios.post(`${apiUrl}/employee_crud.php`, bodyData, {
          headers: { 'Content-Type': 'application/json' }
        });
        
        if (response.data.success) {
          toast.success("Cert successfully deleted!", {
            position: "top-center"
          });
          setEmployeeCerts((prevCerts) => prevCerts.filter(cert => cert.id !== id));
        } else {
          toast.error(response.data.error || "Failed deleting.", {
            position: "top-center"
          });
        }
      } catch (error) {
          if (error.response && error.response.status === 401) {
          toast.error("Session expired. Please login again.", {
          position: "top-center"
          });
          navigate('/login');
          } else if (error.response && error.response.status === 403) {
            setAccess(true);
          toast.error("Oops!! no access to edit.", {
          position: "top-center"
          });
          }
      }
  }

  const handleFormSubmit = async (file, certId) => {
    if (!file) {
      toast.error("Please upload a valid file.", { position: 'top-center' });
      return;
    }
    const formData = new FormData();
    formData.append('status', 'submit_cert_file');
    formData.append('id', certId); // Include certificate ID
    formData.append('file', file);
    try {
      const response = await axios.post(`${apiUrl}/certification_file_crud.php`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
        setEmployeeCerts(certs => certs.map(cert => {
        if (cert.id === response.data?.certificate_file?.id) {
          return { ...cert, file_path: response.data?.certificate_file?.file_path };
        }
            return cert;
        }));

      if (response.data.success) {
        toast.info('Saved!', { position: 'top-center' });
      } else {
        toast.error(response.data.error, { position: 'top-center' });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
      toast.error("Session expired. Please login again.", {
      position: "top-center"
      });
      navigate('/login');
      } else if (error.response && error.response.status === 403) {
      toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
      position: "top-center"
      });
      }
    }
  };


  return (
    <>
      <div className="p-4">
        <h1>Details</h1>
        <br/>
        <div className="columns-3xs ...">
          <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="mt-6 text-sm font-medium text-gray-900">{employeeDetail.first_name} {employeeDetail.last_name}</h3>
              <dl className="mt-1 flex flex-grow flex-col justify-between">
                <dt className="sr-only">Title</dt>
                <dd className="text-sm text-gray-500">{employeeDetail.title} <br/> <span className='text-blue-400'>{employeeDetail.department}</span></dd>
                <dd className="text-sm text-gray-500">{employeeDetail.phone}</dd>
                <dd className="text-sm text-gray-500">{employeeDetail.email}</dd>
                <dd className="text-sm text-gray-500">{employeeDetail.date_hire}</dd>
                <dt className="sr-only">Role</dt> 
                <dd className="mt-3">
                  {employeeDetail.o_f_type === 'Field' ? (
                    <span className="inline-flex items-center rounded-full bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-600/20">
                      {employeeDetail.o_f_type}
                    </span>
                  ) : (
                    <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                      {employeeDetail.o_f_type}
                    </span>
                  )}
                </dd>
              </dl>
            </div>
          </div>

          {employeeFleet && (
            <div className="divide-gray-200 overflow-hidden rounded-lg bg-white shadow mt-3 mb-3">
              <p className='ml-8 mt-4'>Fleet</p>
              <div className="px-4 py-2 sm:p-6">
                <h3 className="mt-6 text-sm font-medium text-gray-900">
                  Vehicle #{employeeFleet.vnumber}
                </h3>
                <dl className="mt-1 flex flex-grow flex-col justify-between">
                  <dt className="sr-only">Title</dt>
                  <dd className="text-sm text-gray-500">{employeeFleet.make} {employeeFleet.model}<br/> 
                  <span className='text-blue-400'>Ins Exp: {employeeFleet.inspection_expires}</span></dd>
                  <dd className="text-sm text-gray-500">Vin: {employeeFleet.vin}</dd>
                  <dd className="text-sm text-gray-500">EzPass: {employeeFleet.ezpass} | {employeeFleet.tag_color} | {employeeFleet.tag_number}</dd>
                </dl>
                <br/>
              </div>
            </div>
          )}
        </div>

        <br/>
        <CustomTableCp certs={employeeCerts} 
                       onEditCert={handleEditCert} 
                       onAddCert={handleAddCert} 
                       access={access}
                       onFileUpload={handleFormSubmit}
                       onDeleteCert={handleDelete}
                       />
      </div>
    </>
  );
}

export default EmployeeDetailCp;
